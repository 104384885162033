

































import {computed, defineComponent} from '@nuxtjs/composition-api';
import { SfButton } from '@storefront-ui/vue'
import { useProductDetails } from '~/bbrTheme/modules/catalog/stores/product';
import {storeToRefs} from 'pinia';

export default defineComponent({
  name: 'AttributesList',
  components: {
    SfButton,
  },
  props: {
    shouldShowButton: {
      type: Boolean,
      default: true,
    },
    allowedAttributes: {
      type: Array,
      default: () => []
    }
  },
  setup() {
    const product = useProductDetails();
    const { getFormattedAttributes, attributes } = storeToRefs(product);
    const eventStartDate = computed(() => attributes.value.find(attr => attr.code === 'event_start_date'));
    const eventStartTime = computed(() => attributes.value.find(attr => attr.code === 'event_start_time'));
    const eventEndTime = computed(() => attributes.value.find(attr => attr.code === 'event_end_time'));

    const eventDate = computed(() => {
      if (!eventStartDate.value?.value) {
        return '';
      }

      let newDate = new Date(eventStartDate.value.value);

      return Intl.DateTimeFormat('en-GB', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      }).format(newDate);
    });

    const eventTime = computed(() => {
      if (!eventStartTime.value?.value || !eventEndTime.value?.value) {
        return '';
      }

      return `${eventStartTime.value.value} - ${eventEndTime.value.value}`;
    });

    return {
      attributes: getFormattedAttributes.value,
      eventDate,
      eventTime
    }
  }
})
