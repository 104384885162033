







































import {
  defineComponent,
  PropType,
} from '@nuxtjs/composition-api';
import Nullable from '@cellar-services/components/atoms/Nullable.vue';
import type { BidItem } from '@cellar-services/types';

export default defineComponent({
  name: 'BidAttributes',
  components: {
    Nullable,
  },
  props: {
    item: {
      type: Object as PropType<BidItem>,
      required: true,
    },
  },
});
