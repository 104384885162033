
























































































































































































// @TODO ALGOLIA: REMOVE WHEN NEW SOLUTION IS IMPLEMENTED
import BidsReceivedModal from '@cellar-services/components/organisms/Modal/BidsReceivedModal.vue'
import type { ItemInterface, BidsForProductInterface } from '@cellar-services/types';
import { defineComponent, PropType, ref, inject } from '@nuxtjs/composition-api';
import Nullable from '@cellar-services/components/atoms/Nullable.vue';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import { SfButton } from '@storefront-ui/vue';

export default defineComponent({
  name: 'BidsReceivedDetails',
  components: {
    SkeletonLoader,
    Nullable,
    SfButton,
    BidsReceivedModal,
    HistoryGraph: () => import('@cellar-services/components/molecules/HistoryGraph.vue'),
  },
  props: {
    item: {
      type: Object as PropType<ItemInterface>,
      required: true
    },
  },
  setup() {
    const isBidsModalOpen = ref<boolean>(false);
    const loading = inject<boolean>('bid-loading')
    const bidData = inject<BidsForProductInterface>('bid-data')

    return {
      isBidsModalOpen,
      bidData,
      loading,
    };
  },
});
