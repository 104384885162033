




































































import {
  defineComponent,
  PropType,
} from '@nuxtjs/composition-api';
import Nullable from '@cellar-services/components/atoms/Nullable.vue';
import type { ItemInterface } from '@cellar-services/types';
import { STORED_STATUS } from '~/bbrTheme/modules/catalog/constants/attributes/stored_status';

export default defineComponent({
  name: 'ValuationAttributes',
  components: {
    Nullable,
  },
  props: {
    item: {
      type: Object as PropType<ItemInterface>,
      required: true,
    },
    isBidsReceivedView: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      STORED_STATUS,
    }
  },
});
