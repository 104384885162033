













































// @TODO ALGOLIA: REMOVE WHEN NEW SOLUTION IS IMPLEMENTED
import {
  defineComponent,
  PropType,
} from '@nuxtjs/composition-api';
import {
  SfLink,
  SfImage,
} from '@storefront-ui/vue';
import HTMLContent from '~/components/HTMLContent.vue';
import CriticScoreSummary from '~/bbrTheme/modules/catalog/product/components/product-types/CriticScoreSummary.vue';
import AttributesList from '~/bbrTheme/modules/catalog/components/AttributesList.vue';
import Maturity from '~/bbrTheme/components/atoms/Maturity.vue';
import { FamilyType, allowedAttributeList } from '~/bbrTheme/modules/catalog/stores/product';
import type { ItemInterface, ValuationInterface } from '@cellar-services/types';
import { getFilteredAttributes, getAttributeValue } from '~/bbrTheme/modules/catalog/getters/productGetters';
import { useOwnership } from '@cellar-services/composables/useOwnership';
import { useCellarUiStore } from '@cellar-services/stores/cellar-ui';

export default defineComponent({
  name: 'Overview',
  components: {
    SfLink,
    SfImage,
    CriticScoreSummary,
    AttributesList,
    Maturity,
    HTMLContent,
  },
  props: {
    item: {
      type: Object as PropType<ItemInterface>,
      required: true,
    },
    valuation: {
      type: Object as PropType<ValuationInterface>,
      required: true,
    },
  },
  setup(props) {
    const familyType = getAttributeValue(props.item, 'family_type');
    const filteredAttributes = getFilteredAttributes(props.item.attributes_value, allowedAttributeList[familyType]);
    const { ownershipNote } = useOwnership(props.item);
    const { setLastActiveItemId } = useCellarUiStore();

    return {
      FamilyType,
      filteredAttributes,
      ownershipNote,
      setLastActiveItemId,
    }
  }
});
