// value enum
export enum StoredStatus {
  DutyPaid = 1,
  InBond = 2,
  WineLyingAbroad = 3,
}

// value x label mapper
export const STORED_STATUS = {
  [StoredStatus.DutyPaid]: 'Duty paid',
  [StoredStatus.InBond]: 'In bond',
  [StoredStatus.WineLyingAbroad]: 'Lying abroad',
};
