































import {
  defineComponent,
  onMounted,
  useContext,
  computed,
  PropType,
} from '@nuxtjs/composition-api';
import {
  getReviewAuthor,
  getReviewScore,
} from '~/modules/review/getters/reviewGetters';
import {
  ProductReview,
} from '~/modules/GraphQL/types';
import { storeToRefs } from 'pinia';
import { useScrollToElement } from '~/bbrTheme/modules/catalog/composables/useScrollToElement';
import { useProductDetails } from '~/bbrTheme/modules/catalog/stores/product';

export default defineComponent({
  name: 'CriticScoreSummary',
  props: {
    reviews: {
      type: Array as PropType<ProductReview[]>,
      default: () => [],
    },
    limit: {
      type: Number,
      default: null,
      required: false,
    },
    enableClick: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { app } = useContext();
    const { scrollTo } = useScrollToElement();
    const store = useProductDetails();
    const { reviews, product } = storeToRefs(store);

    // Limits the number of reviews to the limit prop if it is set
    // Otherwise, it will return all reviews
    const productReviews = computed(() => {
      const scores = props.reviews === null ? reviews.value : props.reviews;
      const limit = props.limit === null ? scores.length : props.limit;

      return scores.slice(0, limit);
    });

    onMounted(() => {
      if (!props.reviews.length && product.value.review_count) {
        store.fetchReviews(app.$vsf, {
          filter: {
            sku: {
              eq: product.value.sku,
            },
          },
        });
      }
    });

    return {
      getReviewAuthor,
      getReviewScore,
      productReviews,
      scrollTo,
      product
    }
  }
})
