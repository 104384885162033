


































import { defineComponent, computed, useContext, toRefs } from '@nuxtjs/composition-api';
import Pagination from '~/bbrTheme/components/Pagination.vue';
import { storeToRefs } from 'pinia';
import { SfButton } from '@storefront-ui/vue';
import { PropType } from 'vue';
import { useFilters } from '~/modules/amplience/stores/filters';
import { useCellarStore } from '@cellar-services/stores/cellar';
import { useListingsStore, useBidsReceivedStore, useBidsPlacedStore } from '@cellar-services/stores/bbx';
import { useWishlistStore } from '~/modules/wishlist/store/wishlistStore';

const STORE_KEY = {
  AMPLIENCE: 'amplience-filters',
  CELLAR: 'cellar-items',
  WISHLIST: 'wishlist',
  BBX_LISTINGS: 'bbx-listings',
  BBX_BIDS_RECEIVED: 'bbx-bids-received',
  BBX_BIDS_PLACED: 'bbx-bids-placed',
};

const BASIC_EVENT_STORES = [
  STORE_KEY.BBX_LISTINGS,
  STORE_KEY.CELLAR,
  STORE_KEY.BBX_BIDS_RECEIVED,
  STORE_KEY.BBX_BIDS_PLACED,
]

export default defineComponent({
  name: 'PaginationBar',
  components: {
    Pagination,
    SfButton
  },
  props: {
    pageSizes: {
      type: Array as PropType<number[]>,
      default: () => [24, 36],
    },
    itemsText: {
      type: String,
      default: 'items',
    },
    topElementId: {
      type: String,
      required: true,
    },
    storeKey: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { itemsText, topElementId, storeKey } = toRefs(props);
    const context = useContext();

    let store = null;

    switch (storeKey.value) {
    case STORE_KEY.AMPLIENCE:
      store = useFilters();
      break;

    case STORE_KEY.CELLAR:
      store = useCellarStore();
      break;

    case STORE_KEY.WISHLIST:
      store = useWishlistStore();
      break;

    case STORE_KEY.BBX_LISTINGS:
      store = useListingsStore();
      break;

    case STORE_KEY.BBX_BIDS_RECEIVED:
      store = useBidsReceivedStore();
      break;

    case STORE_KEY.BBX_BIDS_PLACED:
      store = useBidsPlacedStore();
      break;

    default:
      break;
    }

    const {
      pageSize,
      totalItems,
      totalPages,
      currentPage,
      itemsRange,
    } = storeToRefs(store);

    const perPageText = computed(() => context.app.i18n.t(`${itemsText.value} per page`));

    const showingText = computed(() => context.app.i18n.t(`Showing {0}-{1} of {2} ${itemsText.value}`, [
      itemsRange.value.start,
      itemsRange.value.end,
      totalItems.value,
    ]));

    const scrollToTop = () => {
      const element = document.getElementById(topElementId.value);

      element.scrollIntoView({ behavior: 'smooth' })
    };

    const setCurrentPage = async (page: number): Promise<void> => {
      if (BASIC_EVENT_STORES.includes(storeKey.value)) {
        emit('set-page', page, pageSize.value);
      } else if (storeKey.value === STORE_KEY.WISHLIST) {
        emit('set-current-page', page);
      } else {
        await store.setCurrentPage(context, page);
      }

      scrollToTop();
    };

    const setPageSize = async (size: number): Promise<void> => {
      if (BASIC_EVENT_STORES.includes(storeKey.value)) {
        emit('set-page', currentPage.value, size);
      } else if (storeKey.value === STORE_KEY.WISHLIST) {
        emit('set-page-size', size);
      } else {
        await store.setPageSize(context, size);
      }

      scrollToTop();
    };

    return {
      showingText,
      perPageText,
      currentPageSize: pageSize,
      totalPages,
      setCurrentPage,
      setPageSize,
      currentPage,
      totalItems,
    }
  },
})
