import { ItemInterface, Ownership } from '@cellar-services/types';
import { computed, useContext } from '@nuxtjs/composition-api';

// @TODO ALGOLIA: REMOVE WHEN NEW SOLUTION IS IMPLEMENTED
export const useOwnership = (item: ItemInterface) => {
  const { i18n } = useContext();

  const isFullOwner = computed<Boolean>(() => item.ownership === Ownership.FULL_OWNER);
  const isRentPayer = computed<Boolean>(() => item.ownership === Ownership.RENT_PAYER);
  const isBeneficialOwner = computed<Boolean>(() => item.ownership === Ownership.BENEFICIAL_OWNER);

  const ownershipNote = computed<string>((): string => {
    if (isRentPayer.value && item.beneficial_owner) {
      return i18n.t('This item is stored on behalf of {0}', [item.beneficial_owner]) as string;
    }

    if (isBeneficialOwner.value && item.rent_payer) {
      return i18n.t('Storage on this item is paid for by {0}', [item.rent_payer]) as string;
    }

    return '';
  });

  return {
    ownershipNote,
    isFullOwner,
  }
}
