















































































































































































// @TODO ALGOLIA: REMOVE WHEN NEW SOLUTION IS IMPLEMENTED
import {
  defineComponent,
  ref,
  PropType,
  watch,
  toRef,
  computed,
  useRoute,
  provide,
  nextTick,
  useContext,
} from '@nuxtjs/composition-api';
import {
  SfImage,
  SfButton,
} from '@storefront-ui/vue';
import Maturity from '~/bbrTheme/components/atoms/Maturity.vue';
import BidsReceivedDetails from '@cellar-services/components/molecules/Item/BidsReceivedDetails.vue';
import BidsPlacedDetails from '@cellar-services/components/molecules/Item/BidsPlacedDetails.vue';
import Overview from '@cellar-services/components/molecules/Item/Overview.vue';
import Valuation from '@cellar-services/components/molecules/Item/Valuation.vue';
import Tabs from '~/bbrTheme/components/organisms/Tabs.vue';
import CellarAttributes from '@cellar-services/components/molecules/Attributes/CellarAttributes.vue';
import ValuationAttributes from '@cellar-services/components/molecules/Attributes/ValuationAttributes.vue';
import BidAttributes from '~/integrations/cellar-services/src/components/molecules/Attributes/BidAttributes.vue';
import { FamilyType } from '~/bbrTheme/modules/catalog/stores/product';
import type { ValuationInterface, ItemInterface } from '@cellar-services/types';
import { useMarketplace, EMPTY_VALUATION } from '@cellar-services/composables/useMarketplace';
import Checkbox from '~/bbrTheme/components/form/Checkbox.vue';
import { StoredStatus } from '~/bbrTheme/modules/catalog/constants/attributes/stored_status';
import { SELLING_OPTIONS_MODAL_CONTENT } from '@cellar-services/constants/sellingOptionsModalContent';
import { useOwnership } from '@cellar-services/composables/useOwnership'
import { BIDS_PLACED_MODAL_CONTENT } from '@cellar-services/constants/bidsPlacedModalContent';
import Notification from '@theme/components/molecules/Notification.vue';
import { useBidsForProduct } from '@cellar-services/composables/useBBX';
import { useCustomerStore } from '~/modules/customer/stores/customer';
import { useCellarUiStore } from '@cellar-services/stores/cellar-ui';
import { scrollIntoView } from 'seamless-scroll-polyfill';
import { storeToRefs } from 'pinia';

/* eslint-disable max-len */
const NOTICE = {
  SPIRIT: 'Spirits cannot currently be sold with BBX.',
  SPLIT_CASE: 'Only complete cases can be sold with BBX. Part cases cannot be sold.',
  DUTY_PAID: 'Only In Bond wines can be listed for sale with BBX. As this wine is Duty Paid, it cannot be sold with BBX.',
  EN_PRIMEUR: 'This wine is not available for delivery and cannot be sold with BBX as it has not arrived in our warehouse yet.',
  BENEFICIARY: 'This wine is stored on behalf of a beneficial owner. To get help, contact the customer service team here.',
  BBX_NOT_ALLOWED: 'This wine cannot be sold with BBX. To get help, contact the customer service team here.',
};
/* eslint-enable max-len */

export default defineComponent({
  name: 'ItemRow',
  components: {
    SfImage,
    SfButton,
    // below three components are passed as props
    /* eslint-disable vue/no-unused-components */
    BidsReceivedDetails,
    BidsPlacedDetails,
    Overview,
    Valuation,
    /* eslint-enable vue/no-unused-components */
    Tabs,
    Maturity,
    CellarAttributes,
    ValuationAttributes,
    BidAttributes,
    SellingOptionsModal: () => import('@cellar-services/components/organisms/Modal/SellingOptionsModal.vue'),
    ArrangeDeliveryModal: () => import('@cellar-services/components/organisms/Modal/ArrangeDeliveryModal.vue'),
    BidsPlacedModal: () => import('@cellar-services/components/organisms/Modal/BidsPlacedModal.vue'),
    Checkbox,
    HistoryGraph: () => import('@cellar-services/components/molecules/HistoryGraph.vue'),
    Notification,
  },
  props: {
    item: {
      type: Object as PropType<ItemInterface>,
      required: true,
    },
    isValuationView: {
      type: Boolean,
      default: false,
    },
    isBidsPlacedView: {
      type: Boolean,
      default: false,
    },
    isBidsReceivedView: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    isSelectable: {
      type: Boolean,
      default: false
    },
    isEditModalOpen: {
      type: Boolean,
      default: false
    },
    scrollToRow: {
      type: Boolean,
      default: false
    }
  },
  emits: ['toggle-selected-row'],
  setup(props) {
    const isActive = toRef(props, 'isActive');
    const isEditModalOpen = toRef(props, 'isEditModalOpen');
    const isSellingModalOpen = ref<boolean>(false);
    const isDeliveryModalOpen = ref<boolean>(false);
    const isBidsPlacedModalOpen = ref<boolean>(false);
    const activeBidsPlacedContent = ref<string>(BIDS_PLACED_MODAL_CONTENT.MANAGE_BIDS);
    const activeSellingOptionsContent = ref<string>(SELLING_OPTIONS_MODAL_CONTENT.SELLING_OPTIONS);
    const valuation = ref<ValuationInterface>(EMPTY_VALUATION);
    const tabIndex = ref<number>(0);
    const itemRowRef = ref<HTMLElement | null>(null);

    const { $scrollIntoView } = useContext();
    const { getValuation } = useMarketplace();
    const { ownershipNote, isFullOwner } = useOwnership(props.item);
    const route = useRoute();
    const { loading: bidLoading, data: bidData, getBidsForProduct } = useBidsForProduct();
    const customerStore = useCustomerStore();
    const { sapDefaultID } = storeToRefs(customerStore);
    const cellarUi = useCellarUiStore();
    const { isOpenedFromHistory } = storeToRefs(cellarUi);
    const { setLastActiveItemId, setIsOpenedFromHistory } = cellarUi;

    provide('bid-loading', bidLoading);
    provide('bid-data', bidData);

    let ctaNotice: string = null;
    let canBeDelivered = true;
    let canBeSold = true;

    if (props.isBidsPlacedView) {
      canBeDelivered = false;
      canBeSold = false;
    } else if (!isFullOwner.value) {
      ctaNotice = NOTICE.BENEFICIARY;
      canBeDelivered = false;
      canBeSold = false;
    } else if (props.item.stored_status === StoredStatus.WineLyingAbroad) {
      ctaNotice = NOTICE.EN_PRIMEUR;
      canBeDelivered = false;
      canBeSold = false;
    } else if (props.item.family_type === FamilyType.Spirits) {
      ctaNotice = NOTICE.SPIRIT;
      canBeSold = false;
    } else if (props.item.cases_qty < 1) {
      ctaNotice = NOTICE.SPLIT_CASE;
      canBeSold = false;
    } else if (props.item.stored_status === StoredStatus.DutyPaid) {
      ctaNotice = NOTICE.DUTY_PAID;
      canBeSold = false;
    } else if (!props.item.is_bbx_allowed) {
      ctaNotice = NOTICE.BBX_NOT_ALLOWED;
      canBeSold = false;
    }

    const setTabIndex = (index: number) => {
      tabIndex.value = index;
    };

    const formatItemLabel = (item: ItemInterface) => {
      const { name, colour, maturity, case_order_unit, bottle_volume, cases_qty, bottles_qty } = item;

      const sentences = [
        `View more details for: ${name}`,
        `Type: ${colour} wine`,
        `Maturity: ${maturity}`,
        `Format: ${case_order_unit} x ${bottle_volume}`,
        `Cases: ${cases_qty}`,
        `Bottles: ${bottles_qty}`,
        'Price paid: £5703.50',
        'Status: In bond',
      ];

      return sentences.join('. ');
    }

    const tabTitles = computed(() => {
      const titles = ['Overview', 'Valuation'];

      if (canBeSold) {
        titles.push('Bids received');
      }

      if (props.isBidsPlacedView) {
        titles.push('My bid placed');
      }

      return titles;
    })

    const tabs = computed(() => {
      const tabs: (
        typeof Overview
        | typeof Valuation
        | typeof BidsReceivedDetails
        | typeof BidsPlacedDetails
      )[] = [Overview, Valuation];

      if (canBeSold) {
        tabs.push(BidsReceivedDetails);
      }

      if (props.isBidsPlacedView) {
        tabs.push(BidsPlacedDetails);
      }

      return tabs;
    });

    const defaultOpenTab = computed(() => {
      return (route.value.path.includes('bids-received') || route.value.path.includes('bids-placed')) ? 2 : 0;
    });

    const openSellingOptions = () => {
      isSellingModalOpen.value = true;
      activeSellingOptionsContent.value = SELLING_OPTIONS_MODAL_CONTENT.SELLING_OPTIONS;
    };

    const scrollTo = () => {
      nextTick(() => {
        $scrollIntoView(itemRowRef, {
          behavior: 'smooth',
          block: 'start',
        });
      });
    };

    watch(isActive, async () => {
      if (isActive.value) {
        scrollTo();

        valuation.value = await getValuation({ productSku: props.item.sku });

        getBidsForProduct({
          account_id: sapDefaultID.value,
          product_sku: props.item.sku
        });
      }
    });

    watch(isEditModalOpen, () => {
      if (isEditModalOpen.value) {
        activeSellingOptionsContent.value = SELLING_OPTIONS_MODAL_CONTENT.LIST_ON_BBX;
      }
    });

    watch(() => isOpenedFromHistory.value, (newValue) => {
      if (newValue && props.scrollToRow) {
        scrollTo();
        setLastActiveItemId(null);
        setIsOpenedFromHistory(false);
      }
    }, { immediate: true });

    return {
      tabs,
      tabTitles,
      formatItemLabel,
      ctaNotice,
      canBeDelivered,
      canBeSold,
      isSellingModalOpen,
      isDeliveryModalOpen,
      isBidsPlacedModalOpen,
      activeBidsPlacedContent,
      BIDS_PLACED_MODAL_CONTENT,
      valuation,
      FamilyType,
      tabIndex,
      setTabIndex,
      ownershipNote,
      defaultOpenTab,
      isFullOwner,
      activeSellingOptionsContent,
      SELLING_OPTIONS_MODAL_CONTENT,
      openSellingOptions,
      bidData,
      itemRowRef,
    }
  },
});
