















































// @TODO ALGOLIA: REMOVE WHEN NEW SOLUTION IS IMPLEMENTED
import type { ItemInterface, BidsForProductInterface, AcceptedBidInterface } from '@cellar-services/types';
import { defineComponent, PropType, ref, computed } from '@nuxtjs/composition-api';
import Modal from '@theme/components/organisms/Modal.vue';
import { useBBXBidsReceived } from '@cellar-services/composables/useBBX';

enum CONTENT {
  VIEW_BIDS,
  ACCEPT_BID,
  REJECT_BID,
};

export default defineComponent({
  name: 'BidsReceivedModal',
  components: {
    Modal,
    ViewBidsReceived: () =>
      import('@cellar-services/components/molecules/ModalContent/ViewBidsReceived.vue'),
    AcceptBid: () => import('@cellar-services/components/molecules/ModalContent/AcceptBid.vue'),
    RejectBid: () => import('@cellar-services/components/molecules/ModalContent/RejectBid.vue'),
    AcceptBidConfirmation: () =>
      import('@cellar-services/components/molecules/ModalContent/AcceptBidConfirmation.vue'),
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object as PropType<ItemInterface>,
      required: true,
    },
    bidData: {
      type: Object as PropType<BidsForProductInterface>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const shouldOpenConfirmationModal = ref<boolean>(false);
    const activeContent = ref<CONTENT>(CONTENT.VIEW_BIDS);
    const acceptedBid = ref<AcceptedBidInterface>();

    const { getBidsReceived } = useBBXBidsReceived();

    const sortedBids = computed(() => {
      return [...props.bidData.current_bids]
        .sort((a, b) => b.proposed_price - a.proposed_price);
    });

    const highestBid = computed(() => sortedBids.value[0]);

    const lowerBids = computed(() => sortedBids.value.slice(1));

    const shouldOpenBidsReceived = computed<boolean>(() => {
      return activeContent.value === CONTENT.VIEW_BIDS && !shouldOpenConfirmationModal.value
    });

    const shouldOpenAcceptBid = computed<boolean>(() => {
      return activeContent.value === CONTENT.ACCEPT_BID && !shouldOpenConfirmationModal.value
    });

    const shouldOpenRejectBid = computed<boolean>(() => {
      return activeContent.value === CONTENT.REJECT_BID && !shouldOpenConfirmationModal.value
    });

    const showBackLabel = computed<boolean>(() => {
      return activeContent.value !== CONTENT.VIEW_BIDS && !shouldOpenConfirmationModal.value;
    });

    const handleCloseModal = () => {
      shouldOpenConfirmationModal.value = false;
      activeContent.value = CONTENT.VIEW_BIDS
      getBidsReceived();
      emit('close');
    };

    const handleSuccessAcceptBid = (bid: AcceptedBidInterface) => {
      shouldOpenConfirmationModal.value = true;
      acceptedBid.value = bid;
    };

    return {
      CONTENT,
      activeContent,
      highestBid,
      lowerBids,
      sortedBids,
      shouldOpenBidsReceived,
      shouldOpenAcceptBid,
      shouldOpenRejectBid,
      shouldOpenConfirmationModal,
      handleCloseModal,
      handleSuccessAcceptBid,
      acceptedBid,
      showBackLabel,
    };
  },
});
